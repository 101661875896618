import React from "react";
import { BsFacebook, BsTelegram, BsTiktok, BsTwitter } from "react-icons/bs";

export default function Footer() {
  return (
    <div className="bg-themePurple">
      <div className="w-3/4 m-auto text-center py-12">
        <div className="grid md:grid-cols-2 items-center text-white text-start gap-x-5 gap-y-8">
          <div className="my-12">
            <img className="h-16 m-auto" src="images/logo.png" alt="logo" />
          </div>
          {/* <div>
            <h1 className="font-semibold text-xl">Community</h1>
            <ul className="text-white text-opacity-60">
              <li>Tutorial</li>
              <li>Documentation</li>
              <li>Forum</li>
            </ul>
          </div>
          <div>
            <h1 className="font-semibold text-xl">Services</h1>
            <ul className="text-white text-opacity-60">
              <li>Support </li>
              <li>Upgrade</li>
              <li>Education</li>
            </ul>
          </div>
          <div>
            <h1 className="font-semibold text-xl">About Us</h1>
            <ul className="text-white text-opacity-60">
            <li><a href="/privacy-policy"> Privacy Policy</a></li>
              <li><a href="/terms"> Terms & Conditions</a></li>
            </ul>
          </div> */}
          <div className="text-white text-opacity-60 space-y-5 m-auto">
            <p>Email : support@theshippinghack.com</p>
            <div className="social flex gap-x-8">
              <BsFacebook />
              <BsTwitter />
              <BsTelegram />
              <BsTiktok />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center bg-themePurple text-white py-4 border-t-2">
        <h3>
          Copyright © 2023 Shipping Hack. All Rights Reserved. | Privacy Policy
          | Terms of Use
        </h3>
      </div>
    </div>
  );
}
