import React from "react";
import Card from "./Card";

export default function ChooseUs() {
  return (
    <div className="w-11/12 md:w-[70%] m-auto py-32">
      <h3 className="text-3xl md:text-5xl font-semibold text-center">
        Why Choose Us for International Parcel Forwarding
      </h3>
      <div className="grid md:grid-cols-2 py-10 gap-8">
        <Card
          img={1}
          title="Global Reliability, Local Comfort"
          desc="Experience reliable international parcel forwarding services with a local touch. We ensure your packages reach their global destinations securely and on time, regardless of the international location.
          "
        />
        <Card
          img={2}
          title="Worldwide Flexibility, Your Schedule"
          desc="Take control of your global shipments. Our platform offers you the flexibility to manage deliveries at your pace and according to your international shipping needs"
        />
        <Card
          img={3}
          title="Best International Rates, Hassle-Free Process"
          desc="Unlock the best international rates without sacrificing quality. Our streamlined process makes global parcel forwarding easy and hassle-free for you."
        />
        <Card
          img={4}
          title="Transparent Earnings for Global Deliveries"
          desc="Keep track of your shipments and the costs associated with your global deliveries. Our transparent system provides insight into your global shipping expenses."
        />
        <Card
          img={4}
          title="Customer-Centric International Approach"
          desc="We prioritize your satisfaction. From efficient global deliveries to responsive customer service, we are dedicated to meeting your international shipping needs and ensuring your satisfaction."
        />
        <Card
          img={4}
          title="Effortless Global Document Management"
          desc="Stay globally compliant. Manage and update your essential documents seamlessly, ensuring you're always ready for your global shipments."
        />
      </div>
    </div>
  );
}
