import React from "react";

export default function Working() {
  const divStyle = {
    background: `url("images/rec.webp")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <>
      <div
        style={divStyle}
        className="p-10 relative after:content-[''] after:bg-[#00538C76] after:w-full after:h-full after:absolute after:top-0 after:left-0"
      >
        <div className="border-none  border-2  p-2 md:p-10  m-2 md:m-10 rounded-lg bg-white relative z-10">
          <div className="text-center font-bold text-3xl text-blue-800 my-5">
            How It Works
            <div className="grid md:grid-cols-5 my-6 gap-x-5 gap-y-5">
              <div className="border-solid border-2 text-[#00538C] rounded-lg h-50   mx-auto  text-justify p-5">
                01
                <div className=" text-center ">
                  <div>
                    <img
                      className="h-[66px] m-auto"
                      src="images/5 (1).webp"
                      alt=""
                    />
                  </div>

                  <div>
                    <p className="font-bold text-black text-lg">
                      Sign up and get your Shipping Hack USA Adress
                    </p>
                    <p className="font-normal text-gray-400 text-sm">
                      This will be your own personal address in the USA.No
                      signup or setup fees.
                    </p>
                  </div>
                </div>
              </div>

              <div className="border-solid border-2 text-#FFFFFF rounded-lg h-50   mx-auto text-justify p-5">
                02
                <div className=" text-center ">
                  <div>
                    <img
                      className="h-[66px] m-auto"
                      src="images/5 (2).webp"
                      alt=""
                    />
                  </div>

                  <div>
                    <p className="font-bold text-black text-lg">
                      Shop in any USA Store and ship it to your new USA address
                    </p>
                    <p className="font-normal text-gray-400 text-sm">
                      Use your Shipping Hack U.S. address as your delivery
                      address at checkout
                    </p>
                  </div>
                </div>
              </div>

              <div className="border-solid border-2 text-#FFFFFF rounded-lg h-50   mx-auto text-justify p-5">
                03
                <div className=" text-center ">
                  <div>
                    <img
                      className="h-[66px] m-auto"
                      src="images/5 (3).webp"
                      alt=""
                    />
                  </div>

                  <div>
                    <p className="font-bold text-black text-lg">
                      We will let you know when your package arrives.
                    </p>
                    <p className="font-normal text-gray-400 text-sm">
                      You can select to have special services for your package
                      like:Consoliadation, self-pickup and deliver to address.
                    </p>
                  </div>
                </div>
              </div>

              <div className="border-solid border-2 text-#FFFFFF rounded-lg h-50   mx-auto text-justify p-5">
                04
                <div className=" text-center ">
                  <div>
                    <img
                      className="h-[66px] m-auto"
                      src="images/5 (4).webp"
                      alt=""
                    />
                  </div>

                  <div>
                    <p className="font-bold text-black text-lg">
                      Select your address and make your payments{" "}
                    </p>
                    <p className="font-normal text-gray-400 text-sm">
                      When you select your address and make your payment you
                      have done. relax we will deliver your package at your
                      address.
                    </p>
                  </div>
                </div>
              </div>

              <div className="border-solid border-2 text-#FFFFFF rounded-lg h-50   mx-auto text-justify p-5">
                05
                <div className=" text-center ">
                  <div>
                    <img
                      className="h-[66px] m-auto"
                      src="images/5 (1).webp"
                      alt=""
                    />
                  </div>

                  <div>
                    <p className="font-bold text-black text-lg">
                      Shope Some More:We Love Packages as Much as You Do!
                    </p>
                    <p className="font-normal text-gray-400 text-sm">
                      Enjoy seamless shipping with fellow package enthusiasts at
                      Shipping Hack.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-96">
        
        <video className="h-full w-3/5 mx-auto"  controls>
          <source src="/how.mp4" type="video/mp4" />
        </video>
      </div>
    </>
  );
}
