import React from "react";

export default function Card(props) {
  return (
    <div className="bg-[#F8F8F8] rounded-lg shadow-lg p-10 ">
      <div className="grid grid-cols-3">
        <div className="col-span-1 flex justify-center">
          <div className="bg-themePurple flex justify-center items-center h-16 w-16 rounded-fullest">
            <img className="h-10" src={`/images/${props.img}.png`} alt={props.title} />
          </div>
        </div>
        <div className="col-span-2">
            <h3 className="font-semibold text-lg">{props.title} </h3>
            <p>{props.desc}</p>
        </div>
      </div>
    </div>
  );
}
