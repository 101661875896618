import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import ChooseUs from "../../components/ChooseUs";
import FreeAddress from "../../components/FreeAddress";
import Track from "../../components/Track";
import FAQ from "../../components/FAQ";
import Working from "../../components/Working";
import App from "../../components/App";
import Customers from "../../components/Customers";


export default function Home() {
  return (
    <>
      <Header />
      <Banner />
      <ChooseUs/>
      <FreeAddress/>
      <Track/>
      <Working/>
      
     
      <FAQ/>  
      <Footer />
    </>
  );
}
