import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

export default function FAQ() {
  return (
    <div className="p-7 md:p-20 bg-white text-center">
      <p className="text-black font-bold text-3xl">
        Got any questions? Here our most frequently asked questions
      </p>
      <div className="grid md:grid-cols-2 ">
        <div>
          <img src="images/Group.webp" width={500} alt="" className="p-20" />
        </div>
        <Accordion
          defaultIndex={[0]}
          allowMultiple
          className="text-blue-700 leading-10 m-8"
        >
          <AccordionItem>
            <h2 className="leading-10 ">
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontSize={20}>
                  How does The Shipping Hack work?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              The Shipping Hack simplifies international parcel forwarding
              through our customer app. Get your US-based address and forward
              your packages from the USA to your destination in Puerto Rico with
              ease.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontSize={20}>
                  Can I track my shipments through the app?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Absolutely! You can easily track your packages using The Shipping
              Hack customer app. Real-time tracking updates are available for
              all your forwarded shipments.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontSize={20}>
                  What destinations do you ship to?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Our service offers shipping to anywhere in Puerto, managed
              conveniently through our customer app.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontSize={20}>
                  How can I get support if I have questions or issues?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Our dedicated customer support team is available to assist you.
              Reach out to us for any questions or concerns at
              support@theshippinghack, and we'll be happy to help.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontSize={20}>
                  How are shipping rates calculated in the app?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Shipping rates in the app are based on package weight, dimensions,
              destination, and the selected shipping method (DHL, FedEX, USPS
              etc.m). We aim to provide the most competitive rates for your
              shipments.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontSize={20}>
                  Can I consolidate multiple packages into one shipment using
                  the app?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Yes, the app offers package consolidation services, enabling you
              to combine multiple packages into a single shipment to save on
              shipping costs.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}
