import ButtonTheme from "../utilities/Buttons";
import React from "react";

export default function FreeAddress() {
  return (
    <div className="bg-themePurple py-16">
      <div className="w-4/5 m-auto grid md:grid-cols-2 items-center">
        <div>
          <img className="h-auto w-96" src="/images/address.png" alt="" />
        </div>
        <div>
          <h3 className="text-3xl md:text-5xl text-white my-5">
            Get your free USA address!
          </h3>
          <div className="py-6 text-center">
            <ButtonTheme text="Sign up Free" />
          </div>
        </div>
      </div>
    </div>
  );
}
