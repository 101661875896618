import React from "react";

export default function Privacy() {
  return (
    <div className="w-4/5 m-auto">
      <h1 className="text-4xl text-center font-semibold my-12">
        Privacy Policy
      </h1>
      <h3 className="text-2xl font-semibold my-3">1. Introduction</h3>
      <h5 className="text-lg font-semibold my-2">Purpose of the Privacy</h5>
      Policy At The Shipping Hack, we're committed to safeguarding your privacy
      and ensuring the security of your information. This Privacy Policy
      outlines how we collect, use, and protect your data when you use our
      international parcel forwarding service.
      <h5 className="text-lg font-semibold my-2">Scope of the Policy</h5>
      This Privacy Policy applies to your use of The Shipping Hack app and our
      services. It details the collection, use, and sharing of your personal and
      package-related information.
      <h3 className="text-2xl font-semibold my-3">2. Information We Collect</h3>
      <h5 className="text-lg font-semibold my-2">Personal Information</h5>
      When you sign up and use our service, we gather your name, address, email,
      phone number, and payment details. This data is crucial for setting up
      your account, managing your packages, and staying in touch with you about
      their status.
      <h5 className="text-lg font-semibold my-2">Package Information</h5>
      As you send packages to our USA warehouse, we collect data about their
      dimensions, weight, contents, and tracking details. This information helps
      us handle deliveries to your specified destination efficiently.
      <h3 className="text-2xl font-semibold my-3 ">
        3. How We Use Your Information
      </h3>
      <h5 className="text-lg font-semibold my-2">Providing Services</h5>
      Your information is primarily used to provide our parcel forwarding
      services. This includes receiving, storing, and forwarding your packages
      to the destination you choose. Communication with Users We keep you
      informed about your package's status and important service updates through
      notifications and communications within the app.
      <h5 className="text-lg font-semibold my-2">Payment Processing</h5>
      Your provided payment information is used to process transactions for the
      services you avail of, including package forwarding, and to generate
      invoices as needed.
      <h3 className="text-2xl font-semibold my-3">
        4. Information Sharing Service
      </h3>
      Providers To deliver our services effectively, we may share your
      information with third-party service providers such as logistics companies
      and payment processors. Legal Compliance In certain cases, we may disclose
      your information if required by law or in response to a valid legal
      request.
      <h3 className="text-2xl font-semibold my-3 ">5. Data Security</h3>
      <h5 className="text-lg font-semibold my-2">Security Measures</h5>
      We implement robust measures to protect your personal information from
      unauthorized access, alteration, or disclosure. Your data's security is a
      top priority for us.
      <h5 className="text-lg font-semibold my-2">Limitations</h5>
      While we take every possible precaution to secure your data, it's
      important to note that no method of transmission over the internet or
      electronic storage is completely foolproof.
      <h3 className="text-2xl font-semibold my-3">
        6. Your Choices and Rights
      </h3>
      <h5 className="text-lg font-semibold my-2">
        Managing Account Information
      </h5>
      You can easily update and manage your account details by logging into The
      Shipping Hack app.
      <h5 className="text-lg font-semibold my-2">
        Right to Access and Correct Information
      </h5>
      You have the right to request details about the personal data we hold and
      can ask for corrections or deletion if needed.
      <h3 className="text-2xl font-semibold my-3 ">7. Children's Privacy</h3>
      Our service is not intended for individuals under the age of 18. We do not
      knowingly collect personal information from children. Parents or guardians
      who suspect their child has provided us with personal information should
      contact us immediately.
      <h3 className="text-2xl font-semibold my-3 ">
        8. Changes to this Policy
      </h3>
      We reserve the right to update this Privacy Policy at any time. We will
      inform you of any changes by posting the revised Privacy Policy on our
      app.
      <h3 className="text-2xl font-semibold my-2">9. Contact Information</h3>
      If you have any queries regarding this Privacy Policy or our services,
      please get in touch with us:
      <section>
        <div className="flex items-center gap-x-3 mt-3">
          <h3 className="text-2xl font-semibold">Email:</h3>
          <a href="mailto:support@theshippinghack.com" className="text-lg">
            Support@theshippinghack.com
          </a>
        </div>
        <div className="flex items-center gap-x-3">
          <h3 className="text-2xl font-semibold">Phone Number:</h3>
          <a href="tel:+1 (787) 485-4624" className="text-lg">
            +1 (787) 485-4624
          </a>
        </div>
        <div className="flex items-center gap-x-3 mb-10">
          <h3 className="text-2xl font-semibold">Address:</h3>
          <span className="text-lg">
            560 Juan J Jimenez Street, San Juan, Puerto Rico, 00918
          </span>
        </div>
      </section>
    </div>
  );
}
