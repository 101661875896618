import React from "react";

export default function ButtonTheme(props) {
  return (
    <button
      className="py-2.5 px-12 rounded bg-themeOrange font-medium text-base text-white border border-themePurple  hover:text-themePurple"
    >
      {props.text}
    </button>
  );
}

export function ButtonTrans(props) {
  return (
    <button
      className="py-2.5 px-12 rounded bg-transparent font-medium text-base text-white border border-white"
    >
      {props.text}
    </button>
  );
}
