import React from "react";
import { Input, InputGroup, InputRightAddon, Stack } from "@chakra-ui/react";
import ButtonTheme from "../utilities/Buttons";
export default function Track() {
  return (
    <div className="py-16">
      <div className="w-4/5 m-auto grid md:grid-cols-2 items-center">
        <div className="p-10">
          <h3 className="text-3xl md:text-5xl">
            Reliable Parcel Delivery Across USA
          </h3>
          <p>
            With our fast delivery, competitive pricing, and round-the-clock
            customer support, you can trust us to get your packages to their
            destination safely and on time.
          </p>
          {/* <div className="flex py-6">
          <input type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter Tracking Number" required/>
            <ButtonTheme text="Track" />
          </div> */}
        </div>
        <div>
          <img className="" src="/images/tracksection.png" alt="" />
        </div>
      </div>
    </div>
  );
}
