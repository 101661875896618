import React from "react";
import { BsTelephone } from "react-icons/bs";
import { IoMailUnreadOutline } from "react-icons/io5";

export default function Header() {
  return (
    <div>
      <header className="bg-themePurple p-2 md:p-3">
        <div className="flex items-center justify-between w-11/12 md:w-4/5  m-auto text-white">
          <div>
            <img className="w-44" src="images/logo.png" alt="logo" />
          </div> 
          <div className="flex items-center justify-end gap-10">
          <div className="flex gap-x-3">
            <div className="flex justify-center items-center h-10 w-10 border border-white rounded-fullest">
              <a href="tel:+1 (787) 485-4624"><BsTelephone className="text-white text-2xl" /></a>
            </div>
            <div className="hidden md:block">
              <p className="text-xs text-themeBlack2">24/7 Helpline</p>
              <a className="p-2" href="tel:+1 (787) 485-4624">+1 (787) 485-4624</a>
            </div>
          </div>
          <div className="flex gap-x-3">
            <div className="flex justify-center items-center h-10 w-10 border border-white rounded-fullest">
              <a className="p-2" href="mailto:support@theshippinghack.com"><IoMailUnreadOutline className="text-white text-2xl" /></a>
            </div>
            <div className="hidden md:block">
              <p className="text-xs text-themeBlack2">Email Support</p>
              <a href="mailto:support@theshippinghack.com">support@theshippinghack.com</a>
            </div>
          </div>
        </div>         
        </div>
      </header>
    </div>
  );
}
