import React from "react";
import { ButtonTrans } from "../utilities/Buttons";

export default function Banner(props) {
  return (
    <div className="bg-themePurple">
      <div className="grid md:grid-cols-2 w-11/12 md:w-4/5 m-auto pt-32 ">
        <div className="text-white px-5 space-y-5">
          <h3 className="text-3xl md:text-5xl font-semibold">
            International Parcel Forwarding Services
          </h3>
          <p className="text-sm md:text-xl">
            Embark on effortless global shipping with The Shipping Hack. Obtain
            your US-based address and start your worldwide deliveries
            hassle-free. Our dedicated support team is always at your service.
            Download the Shipping Hack to get started.
          </p>
          <div className="text-center md:text-left flex flex-col md:flex-row gap-4">
            {/* <ButtonTheme text="Send Package" /> */}
            <ButtonTrans text="Download the App" />
          </div>
        </div>
        <div>
          <img className="w-auto h-auto" src="images/banner-img.png" alt="" />
        </div>
      </div>
    </div>
  );
}
