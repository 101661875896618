import React from "react";

export default function Terms() {
  return (
    <div className="w-4/5 m-auto">
      <header>
        <h1 className=" text-center text-4xl font-semibold my-10">Terms and Conditions - The Shipping Hack</h1>
      </header>

      <section>
        <h2>1. Introduction</h2>

        <article>
          <h3>Purpose of the Terms and Conditions</h3>
          <p>
            Welcome to The Shipping Hack! These Terms and Conditions outline the
            rules and regulations for the use of our international parcel
            forwarding service. By accessing and using our services, you agree
            to abide by these terms.
          </p>
        </article>

        <article>
          <h3>Acceptance of Terms</h3>
          <p>
            By using The Shipping Hack, you acknowledge that you have read,
            understood, and accepted these Terms and Conditions. If you disagree
            with any part of these terms, please refrain from using our
            services.
          </p>
        </article>
      </section>

      <section>
        <h2>2. Use of Services</h2>

        <article>
          <h3>Eligibility</h3>
          <p>
            To use The Shipping Hack, you must be at least 18 years old and
            capable of entering into a binding agreement. By using our services,
            you confirm that you meet these requirements.
          </p>
        </article>

        <article>
          <h3>Account Registration</h3>
          <p>
            Users are responsible for providing accurate and current information
            during the registration process. Accounts must not be shared or
            transferred to others.
          </p>
        </article>

        <article>
          <h3>User Responsibilities</h3>
          <p>
            Users are responsible for maintaining the security of their
            accounts, including safeguarding login credentials and promptly
            updating any changes to personal information.
          </p>
        </article>
      </section>

      <section>
        <h2>3. Services Provided</h2>

        <article>
          <h3>Description of Services</h3>
          <p>
            The Shipping Hack provides international parcel forwarding services,
            allowing users to ship packages to our warehouse and have them
            forwarded to their specified destination.
          </p>
        </article>

        <article>
          <h3>Service Limitations</h3>
          <p>
            While we strive to provide a reliable service, we do not guarantee
            uninterrupted, error-free, or secure access to our services.
          </p>
        </article>
      </section>

      <section>
        <h2>4. User Conduct</h2>

        <article>
          <h3>Prohibited Activities</h3>
          <p>
            Users must not engage in activities that violate laws, infringe on
            the rights of others, or disrupt the proper functioning of our
            services.
          </p>
        </article>

        <article>
          <h3>User Obligations</h3>
          <p>
            Users agree to use our services in compliance with all applicable
            laws and regulations and to refrain from using the services for any
            unlawful or unauthorized purpose.
          </p>
        </article>
      </section>
      <section>
        <h2>5. Fees and Payments</h2>

        <article>
          <h3>Service Charges</h3>
          <p>
            Users agree to pay the fees associated with our services as outlined
            on our platform. Fees may vary based on the services used and
            package forwarding requirements.
          </p>
        </article>

        <article>
          <h3>Payment Terms</h3>
          <p>
            Payments for services are to be made using the designated payment
            methods provided by The Shipping Hack. Users must ensure the
            accuracy of payment details and that sufficient funds are available.
          </p>
        </article>
      </section>

      <section>
        <h2>6. Intellectual Property Rights</h2>

        <article>
          <h3>Ownership of Content</h3>
          <p>
            The Shipping Hack retains ownership of all content and materials
            provided through our services, including but not limited to
            software, text, images, and logos.
          </p>
        </article>

        <article>
          <h3>Use of Company's Intellectual Property</h3>
          <p>
            Users are prohibited from using, copying, or distributing The
            Shipping Hack's intellectual property without explicit
            authorization.
          </p>
        </article>
      </section>

      <section>
        <h2>7. User Data and Privacy</h2>

        <article>
          <h3>Data Collection and Use</h3>
          <p>
            Our Privacy Policy governs the collection, use, and sharing of user
            information. By using our services, you agree to our Privacy Policy.
          </p>
        </article>

        <article>
          <h3>Privacy Protection</h3>
          <p>
            We take data protection and user privacy seriously. We implement
            measures to safeguard the confidentiality and security of user data.
          </p>
        </article>
      </section>

      <section>
        <h2>8. Liability and Disclaimers</h2>

        <article>
          <h3>Limitation of Liability</h3>
          <p>
            The Shipping Hack shall not be held liable for any direct, indirect,
            incidental, special, or consequential damages arising from the use
            or inability to use our services.
          </p>
        </article>

        <article>
          <h3>Disclaimer of Warranties</h3>
          <p>
            We do not warrant that our services will be error-free, secure, or
            uninterrupted. Users agree that the use of our services is at their
            own risk.
          </p>
        </article>
      </section>
      <section>
        <h2>9. Termination and Suspension</h2>

        <article>
          <h3>Termination of Services</h3>
          <p>
            The Shipping Hack reserves the right to terminate or suspend
            services for users who violate the Terms and Conditions or engage in
            unauthorized or illegal activities.
          </p>
        </article>

        <article>
          <h3>Suspension of Accounts</h3>
          <p>
            We may suspend user accounts if there is suspicion of fraudulent
            activities, security breaches, or violation of our policies.
          </p>
        </article>
      </section>

      <section>
        <h2>10. Changes to Terms and Conditions</h2>

        <article>
          <p>
            We reserve the right to modify or replace these Terms and Conditions
            at any time. Users will be notified of any changes, and continued
            use of the services constitutes acceptance of the modified terms.
          </p>
        </article>
      </section>

      <section>
        <h2>11. Contact Information</h2>

        <article>
          <p>
            If you have any questions about our Terms and Conditions or our
            services, please reach out to us using the following contact
            information:
          </p>
          <section>
            <div className="flex items-center gap-x-3 mt-3">
              <h3 className="text-2xl font-semibold">Email:</h3>
              <a href="mailto:support@theshippinghack.com" className="text-lg">
                Support@theshippinghack.com
              </a>
            </div>
            <div className="flex items-center gap-x-3">
              <h3 className="text-2xl font-semibold">Phone Number:</h3>
              <a href="tel:+1 (787) 485-4624" className="text-lg">
                +1 (787) 485-4624
              </a>
            </div>
            <div className="flex items-center gap-x-3 mb-10">
              <h3 className="text-2xl font-semibold">Address:</h3>
              <span className="text-lg">
                560 Juan J Jimenez Street, San Juan, Puerto Rico, 00918
              </span>
            </div>
          </section>
        </article>
      </section>
    </div>
  );
}
